import * as React from "react"
import { Helmet } from "react-helmet"
import tw from "twin.macro"
import { Link } from 'gatsby'

import background from "../images/background.png";
import logo from "../images/logo.svg";


import "./index.css";

const IndexPage = () => (
  <main
    style={{ background: `#000 url(${background})`, backgroundPosition: 'center center' }}
    css={tw`w-screen text-white text-center py-20`}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Storm Lantern Expeditions</title>
    </Helmet>

    <button css={tw`absolute right-10 top-7 tracking-widest hover:text-gray-400 text-sm`}>
      LOGIN
    </button>

    <div css={tw`px-8`}>
      <img
        css={tw`block m-auto max-w-lg`}
        src={logo}
        alt="Storm Lantern Expeditions"
      />

      <p css={tw`text-gray-400 tracking-wide`} style={{ maxWidth: `740px`, fontSize: '18px' }}>
        Storm Lantern creates and leads extraordinary travel experiences without any limits.
      </p>

      <p css={tw`text-gray-400 tracking-wide`} style={{ maxWidth: `740px`, fontSize: '18px' }}>
        We have gathered a "family" of extraordinary people, collected over the years, who share both a passion for life and a common optic on the world. We are driven by curiosity, love, and a desire to leave the world a slightly better place, in whatever way we can contribute.
      </p>

      <p css={tw`text-gray-400 tracking-wide`} style={{ maxWidth: `740px`, fontSize: '18px' }}>
        We are not about any individual. We simply want to create an energy that attracts further like-minded people to pursue this simple ambition. We firmly believe in the whole being greater than the sum of its parts.
      </p>

      <p css={tw`text-gray-400 tracking-wide`} style={{ maxWidth: `740px`, fontSize: '18px' }}>
      We are a stable of original thinkers with enviable provenance of leading expeditions and journeys around the world for over 25 years.
      </p>

      <p css={tw`text-gray-400 tracking-wide`} style={{ maxWidth: `740px`, fontSize: '18px' }}>
      Please ask one of our mutual friends to introduce us.
      </p>

      <p css={tw`text-gray-400 tracking-wide`} style={{ maxWidth: `740px`, fontSize: '18px' }}>
        We look forward to meeting you.
      </p>

      <p css={tw`text-gray-400 text-base tracking-wide`} style={{ marginTop: `10vh` }}>
        <Link css={tw`text-gray-400 tracking-wide underline`} to="/privacy-policy" target="_blank">Privacy policy</Link>
      </p>

      <Link css={tw`text-gray-400 tracking-wide underline`} to="/booking-conditions" target="_blank">Booking conditions</Link>
    </div>
  </main>
);

export default IndexPage;
